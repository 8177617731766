define("discourse/plugins/discourse-software-ag-tech/lib/extend-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(titleTokens, topicTitle, tags, tagsToInclude) {
    if (!tags || tags.length === 0) {
      return titleTokens;
    }
    const titlesToSkip = "webmethods|cumulocity|adabas";
    if (new RegExp(titlesToSkip).test(topicTitle.toLowerCase())) {
      return titleTokens;
    }
    const specialFormats = {
      "adabas-natural": "Adabas & Natural",
      "cumulocity-iot": "Cumulocity IoT"
    };
    const sharedTags = tags.filter(tag => {
      return tagsToInclude.includes(tag);
    });
    if (sharedTags.length === 1) {
      const tag = specialFormats[sharedTags[0].toLowerCase()] || sharedTags[0];
      return [titleTokens].flat().concat(tag);
    } else {
      return titleTokens;
    }
  }
});