define("discourse/plugins/discourse-software-ag-tech/initializers/extend-topic-title", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-software-ag-tech/lib/extend-title"], function (_exports, _pluginApi, _extendTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-topic-title",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.2", api => {
        api.modifyClass("route:topic", {
          pluginId: "discourse-software-ag-tech",
          titleToken() {
            const titleTokens = this._super(...arguments);
            const model = this.modelFor("topic");
            const originalTitle = model.get("unicode_title") || model.get("title");
            return (0, _extendTitle.default)(titleTokens, originalTitle, model.tags, this.siteSettings.include_tags_in_title_tag.split("|"));
          }
        });
      });
    }
  };
});